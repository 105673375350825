<template>
  <div class="edit">
    <h3>审核设置</h3>
    <div class="model" style="margin-top: 50px"></div>
        <el-form ref="form" :model="formData" label-width="100px"  :rules="rules">
          <el-form-item label="项目是否需要审核" prop="project_review">
            <el-radio-group v-model="formData.project_review">
              <el-radio :label="1" >是</el-radio>
              <el-radio :label="2" >否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="职位是否需要审核" prop="position_review">
            <el-radio-group v-model="formData.position_review">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="主管是否需要审核" prop="supervisor_review">
            <el-radio-group v-model="formData.supervisor_review">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="众包是否需要审核" prop="crowdsourcing_review">
            <el-radio-group v-model="formData.crowdsourcing_review">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
  
  </div>
</template>

<script>
  import { auditSettings,getAuditSettings } from "@/api/project/index";

export default {
  components: {
  },
  data() {
    return {

      formData:{
        project_review: 1,
        position_review: 1,
        supervisor_review: 1,
        crowdsourcing_review: 1,

      },

      rules: { },


      codeShow: true, //获取验证码倒计时
      timer: null, //定时器
      count: "", //倒计时数字
     
    };
  },

  mounted(){
    this.initInfo();
  },
  methods: {

    initInfo(){
      var that = this;
      getAuditSettings({}).then((response) => {
        if(response.code==200 && response.data){
          console.log(response.data);
          console.log('元数据',that.formData);

          that.formData.project_review=parseInt(response.data.project_review);
          that.formData.position_review=parseInt(response.data.position_review);
          that.formData.supervisor_review=parseInt(response.data.supervisor_review);
          that.formData.crowdsourcing_review=parseInt(response.data.crowdsourcing_review);
          console.log('查询数据',that.formData);

        }
      });
    },

    onSubmit(){

      this.$refs["form"].validate((valid) => {
          if (valid) {
            auditSettings(this.formData).then((response) => {
                    console.log(this.formData);
                    if(response.code==200){
                      this.$message({
                        message: "提交成功",
                        type: 'success'
                      });
                    }else{
                      this.$message({
                        message: response.msg?response.msg:"提交失败",
                      });
                    }
                });
            } 
        });
    },
    
  },
};
</script>

<style lang="scss" scoped>

/deep/ .el-input{
  width: 300px !important;
}
.edit {
  padding: 20px;
  background-color: white;
}


.codestya {
  color: #4c71db;
  position: absolute;
  left: 250px;
  top: 40px;
  font-size: 12px;
}
.codestyb {
  color: #4c71db;
  position: absolute;
  left: 250px;
  top: 40px;
  font-size: 12px;
}
</style>
